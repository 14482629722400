import { Box, Container, Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#fff', py: 2 }}>
      <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={6} md={12}>
            <Typography variant='body1' align='center'>
              © 2023{' '}
              <a style={{ textDecoration: 'none', color: '#000' }} href='https://tkuprinskas.lt/' target='_blank' rel='noopener noreferrer'>
                tkuprinskas.lt{' '}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
